/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/index.scss"

const calcOriginPath = uri => {
  const uriSplit = uri.split("/")
  const splitLast = uriSplit[uriSplit.length - 1]
  if (splitLast.match(/^\d$/)) {
    uriSplit.pop()
  }

  const originPath = uriSplit.reduce((acc, now) => {
    if (acc == "") {
      return acc + now
    } else {
      return acc + "/" + now
    }
  }, "")

  return originPath
}

class BlogIndex extends React.Component {
  render() {
    const {
      data,
      pageContext: { group, last, first, index },
      uri,
    } = this.props
    const siteTitle = data.site.siteMetadata.title

    // gatsby-paginateにより注入されたpostsEdge
    const posts = group
    const pagePath = calcOriginPath(uri)

    const prevPageNum = index - 1 > 1 ? (index - 1).toString(10) : ""
    const prevPagePath = pagePath + "/" + prevPageNum

    const nextPageNum = (index + 1).toString(10)
    const nextPagePath = pagePath + "/" + nextPageNum

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="makibishi throw" />
        {posts.map(({ node }) => {
          const tags = node.frontmatter.tags
          return (
            <div className="list-item" key={node.fields.slug}>
              <h3 className="list-item__title">
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {node.frontmatter.title}
                </Link>
              </h3>
              <div className="list-item__info">
                <small>{node.frontmatter.date}</small>
                <div className="tags">
                  {tags &&
                    tags.map(tag => {
                      {
                        return (
                          <Link key={tag} className="tag" to={`/tags/${tag}`}>
                            {tag}
                          </Link>
                        )
                      }
                    })}
                </div>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          )
        })}

        <ul className="paginator">
          <li>
            {!first && (
              <Link to={prevPagePath} rel="prev">
                ← 前のページ
              </Link>
            )}
          </li>
          <li>
            {!last && (
              <Link to={nextPagePath} rel="next">
                次のページ →
              </Link>
            )}
          </li>
        </ul>
        <Bio />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
